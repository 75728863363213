import React from "react"
import "./TMHHeaderStyles.scss"
import LogoImg from "./logo.png"

export default function TMHHeader() {
    return (
        <a href="/" className="tmh-header">
            <span className="header-title">
                <span>
            <b>AI SMART</b> Remote</span> <br/>
            <span className="header-title2">The Smart Remote For your TV</span>
            </span>
            <img className="header-logo" src={LogoImg} alt="Roku Remote logo" />
        </a>
    )
}