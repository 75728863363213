import React from "react"
import "./TMHFooterStyles.scss"

export default function TMHFooter() {
    return (
        <footer className="wrapper-footer">
            <a className="footer-item" href="/#/terms-of-use" target="_blank" rel="noreferrer">Terms of use
            </a>
            <a className="footer-item" href="/#/privacy-policy" target="_blank" rel="noreferrer">
                Privacy policy
            </a>
             <a className="footer-item" href="mailto:contact@aismartremote.com">
                Contact
            </a>
        </footer>
    )
}