import React from "react";
import TMHBanner from "../../components/TMHBanner/TMHBanner";
import TMHHeader from "../../components/TMHHeader/TMHHeader";
import TMHFooter from "../../components/TMHFooter/TMHFooter";
import "./LandingPage.scss";
import { useTitle } from "react-use";

export default function LandingPage() {
  useTitle("Home Page - AI Smart Remote");

  return (
    <>
      <TMHBanner />
      <div className="landing-container">
        <TMHHeader />
        <label className="label-1">
          Ai Smart Remote is the ultimate easy to use mobile app to control your
          Roku device and much more. <br />
          <br />
          Set up in seconds and use as your primary or secondary remote in your
          home.
          <br />
          <br />
          Experience unparalleled convenience with quick and easy access to Ai
          Features right inside the app.Start with prompts like:
          <br />
          <br />
          "What episode of Senifeld does Kramer invent a cologne"
          <br />
          <br />
          "When did Ross and Rachel first get together?"
        </label>
        <label className="label-2">
          Download now
          <br />
          for Android
        </label>
        <button
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.ai.smartremoteplus"
            )
          }
          className="play-store-button"
        >
          Go to the playstore
        </button>
        <TMHFooter />
      </div>
    </>
  );
}
