import React from "react"
import TMHBanner from "../../components/TMHBanner/TMHBanner"
import TMHHeader from "../../components/TMHHeader/TMHHeader"
import TMHFooter from "../../components/TMHFooter/TMHFooter"
import "./ExtensionPage.scss"
import Sample1 from "./Sample1.png"
import Sample2 from "./Sample2.png"
import Sample3 from "./Sample3.png"
import { useTitle } from 'react-use'

export default function ExtensionPage() {
    useTitle("Extension Page - AI Smart Remote")

    return (
        <>
            <TMHBanner />
            <div className="extension-container">
                <TMHHeader />
                <label className="label-1">
                    Roku Movie Remote is simply the best FREE mobile remote for Roku while also allowing users free access to New Release Movies. Use the Roku Movie Remote as your primary or secondary remote. <br /> <br />
                    Sync and set up takes less than a minute. On install you'll connect your phone or tablet to the same wireless network as your Roku so you can use the mobile app remote features.
                </label>
                <div className="sample-block">
                    <img src={Sample1} alt="sample-1" />
                    <img src={Sample2} alt="sample-2" />
                    <img src={Sample3} alt="sample-3" />
                </div>
                <label className="label-2">How does it work?</label>
                <label className="label-1" style={{ marginTop: 5 }}>
                    Install the Roku Movie Remote extension for free by clicking the ‘Get’ button. Once you have added it, each time you open a new tab you will see and be able to control your Roku right there in your browser! You can also pin the extension to your tool bar for easy access.
                </label>
                <button className="get-button" onClick={() => window.open("https://chrome.google.com/webstore/detail/roku-remote/jngdnnffdlbidhgfhplljlcpnocckcpc?hl=en&authuser=0", "_blank")}>
                    Add to Chrome now
                </button>
                <TMHFooter mobile={false} />
            </div>
        </>
    )
}